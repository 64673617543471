import * as React from "react";
import { FunctionComponent, useState, useCallback } from "react";
import { Button, IconButton } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { navigate, Link } from "gatsby";
import * as styles from "./shipping-policy.module.css";
import Layout from "../components/Base/Layout";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const ShippingPolicy: FunctionComponent = () => {
  const [isNavigationMobileOpen, setNavigationMobileOpen] = useState(false);

  const onButton1Click = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onButton2Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='contactSectionContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onButton3Click = useCallback(() => {
    navigate("/sign-in-page");
  }, []);

  const openNavigationMobile = useCallback(() => {
    setNavigationMobileOpen(true);
  }, []);

  const closeNavigationMobile = useCallback(() => {
    setNavigationMobileOpen(false);
  }, []);

  const onPrivacyPolicyClick = useCallback(() => {
    navigate("/privacy-policy");
  }, []);

  const onShippingPolicyClick = useCallback(() => {
    navigate("/shipping-policy");
  }, []);

  const onTermsAndConditionsClick = useCallback(() => {
    navigate("/terms-conditions");
  }, []);

  return (
    <>
      <Layout>
      <div className={styles.shippingPolicy}>
        <div className={styles.docTemplate}>
          <div className={styles.textColumn}>
          <div className={styles.title}>
              <b className={styles.display}>Shipping and Refund Policy</b>
            </div>
            <div className={styles.subtitleLarge}>
              <div className={styles.display1}>General Shipping Policies</div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display2}>
                How much do I need to pay for the shipping fee?
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  We charge a flat shipping rate of $15 on orders under $500. Our
                  free shipping is available on orders over $500. Note that our
                  shipping fee may also depend on your location. For orders
                  outside the US, extra charges may apply.
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display2}>
                Do you accept orders abroad?
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  Our services also cater to countries outside the US. We ship
                  worldwide; however, some restrictions may affect our shipping
                  internationally, particularly with geo-political circumstances.
                </div>
              </div>
            </div>
            <div className={styles.subtitleLarge}>
              <div className={styles.display1}>Tracking Your Order</div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display2}>How do I track my package?</div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.youCanQuickly}>
                    You can quickly locate and find tracking information in your
                    order details. Follow the steps:
                  </p>
                  <p className={styles.youCanQuickly}>&nbsp;</p>
                  <ul className={styles.proceedToYourOrdersSelect}>
                    <li className={styles.proceedToYour}>
                      Proceed to your orders.
                    </li>
                    <li className={styles.proceedToYour}>
                      Select the product you want to track.
                    </li>
                    <li className={styles.proceedToYour}>
                      Choose Track Package.
                    </li>
                    <li>
                      You can also select ‘See all updates’ to view your
                      delivery updates.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <b>Note:</b> Some international packages are not trackable. The
                  reason for this concern may include lacking information on the
                  delivery.
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display2}>
                When will I receive my order?
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  The delivery can go smoothly if the information provided is
                  complete and the areas for delivery are unrestricted. We
                  expect orders to arrive within 14 days. Delays can be due to:
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.proceedToYourOrdersSelect}>
                    <li className={styles.proceedToYour}>
                      Incomplete order forms, especially prescription papers and
                      tabs
                    </li>
                    <li className={styles.proceedToYour}>
                      Illegible order forms
                    </li>
                    <li className={styles.proceedToYour}>
                      Inaccurate/Incomplete credit card information (number,
                      name, and expiration date)
                    </li>
                    <li className={styles.proceedToYour}>Unsigned forms</li>
                    <li className={styles.proceedToYour}>
                      Unauthorized transactions regulated by your bank or credit
                      card provider (usually happens in international
                      transactions)
                    </li>
                    <li className={styles.proceedToYour}>
                      Insufficient funds on your credit card
                    </li>
                    <li className={styles.proceedToYour}>
                      Peak periods at holiday time
                    </li>
                    <li>Extreme weather conditions</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display2}>Can I cancel my order?</div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  Cancellation of orders is applicable if the product is not yet
                  prepared or packaged. You can do so without charges or
                  penalties.
                </div>
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  However, an attempt for order cancellation once the order has
                  been shipped, is NOT allowed.
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display2}>
                What happens if my order does not arrive on time?
              </div>
            </div>
            <div className={styles.textbody8}>
              <div className={styles.p}>
                <p className={styles.caption8}>
                  If your order does not arrive at the specified time for
                  delivery, we will re-ship your orders at our expense. You will
                  receive a confirmation text or email telling you your order
                  has been processed and shipped. In the event of the refusal of
                  orders, your package will be sent back to the warehouse.
                </p>
              </div>
            </div>
            <div className={styles.subtitleLarge}>
              <div className={styles.display1}>Requesting for Refund</div>
            </div>
            <div className={styles.textbody9}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.subtitle}>
                    How do I get a refund?
                  </p>
                  <p className={styles.weDoNotAcceptProductOrIt}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.youCanQuickly}>
                    <span>
                      You are eligible for a full refund within 6 months of your
                      purchase. Note that any request for a new or replacement
                      product after this period ends is NOT allowed.
                    </span>
                  </p>
                  <p className={styles.youCanQuickly}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.youCanQuickly}>
                    <span>
                      Depending on the circumstances, refunds are available for
                      any product defects or issues:
                    </span>
                  </p>
                  <p className={styles.youCanQuickly}>
                    <span>&nbsp;</span>
                  </p>
                  <ul className={styles.proceedToYourOrdersSelect}>
                    <li className={styles.proceedToYour}>
                      <span>
                        Replacement product to the same shipping address
                      </span>
                    </li>
                    <li className={styles.proceedToYour}>
                      <span>Receive a full refund</span>
                    </li>
                    <li>
                      <span>Account credit for the item</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody10}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.subtitle}>
                    Can I qualify for a refund?
                  </p>
                  <p className={styles.youCanQuickly}>
                    <b>&nbsp;</b>
                  </p>
                  <p className={styles.youCanQuickly}>
                    <span>
                      Our refund services are available at our own discretion.
                      This service only applies to specific circumstances.
                      However, we strive to ensure you are satisfied and happy
                      with your medical supplies and orders.
                    </span>
                  </p>
                  <p className={styles.youCanQuickly}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.youCanQuickly}>
                    <span>For orders to qualify for a refund:</span>
                  </p>
                  <p className={styles.youCanQuickly}>
                    <span>&nbsp;</span>
                  </p>
                  <ul className={styles.proceedToYourOrdersSelect}>
                    <li className={styles.proceedToYour}>
                      <span>
                        If the product delivered is misrepresented to the
                        ordered item.
                      </span>
                    </li>
                    <li className={styles.proceedToYour}>
                      <span>
                        If the product is received with a damaged package.
                      </span>
                    </li>
                    <li className={styles.proceedToYour}>
                      <span>If the product is defective upon initial use.</span>
                    </li>
                  </ul>
                  <p className={styles.youCanQuickly}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.ifAnyOfTheseFactorsApply}>
                    <span>
                      If any of these factors apply to you, you will receive
                      further instructions from us.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody11}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  {/* <p className={styles.subtitle}>
                    What information do I need to return a product?
                  </p>
                  <p className={styles.youCanQuickly}>
                    <b>&nbsp;</b>
                  </p>
                  <p className={styles.weDoNotAcceptProductOrIt}>
                    <span>
                      We do not accept product or item returns. If your ordered
                      medical device has been found faulty after several uses,
                      just keep the lot number and expiry date. You can report
                      this to our customer service and ask for guidance on
                      handling defective orders.
                    </span>
                  </p> */}
                  <p className={styles.youCanQuickly}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.youCanQuickly}>
                    <span>
                      Should you have more inquiries and concerns about this
                      policy, do not hesitate to <AnchorLink to="/#contactSectionContainer"><a className={styles.contactUs}>contact
                  us</a></AnchorLink> for more
                      information.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Layout>
    </>
  );
};

export default ShippingPolicy;
