// extracted by mini-css-extract-plugin
export var bannerSuper = "shipping-policy-module--bannerSuper--54wdP";
export var button = "shipping-policy-module--button--FBkCn";
export var cASLOGOIcon = "shipping-policy-module--cASLOGOIcon--Qi1lH";
export var canIQualifyForARefund = "shipping-policy-module--canIQualifyForARefund--bC2Dv";
export var canadianSupply = "shipping-policy-module--canadianSupply--0jJ9c";
export var caption = "shipping-policy-module--caption--kurqk";
export var caption8 = "shipping-policy-module--caption8--ZWcJu";
export var ccs = "shipping-policy-module--ccs--Spogx";
export var column = "shipping-policy-module--column--ZJvZc";
export var contactUs = "shipping-policy-module--contactUs--tg01j";
export var display = "shipping-policy-module--display--K9Utr";
export var display1 = "shipping-policy-module--display1--GWeEy";
export var display2 = "shipping-policy-module--display2--ENx3K";
export var docTemplate = "shipping-policy-module--docTemplate--lnmxl";
export var email = "shipping-policy-module--email--FO33P";
export var footer = "shipping-policy-module--footer--TAYGd";
export var footerContent = "shipping-policy-module--footerContent--MGvfi";
export var footerL = "shipping-policy-module--footerL--6yMnx";
export var footerLinks = "shipping-policy-module--footerLinks--n2lzE";
export var footerR = "shipping-policy-module--footerR--rjfPq";
export var fullNavBar = "shipping-policy-module--fullNavBar--aqBiF";
export var iconButton = "shipping-policy-module--iconButton--ydR78";
export var iconFilled = "shipping-policy-module--iconFilled--SyGzQ";
export var ifAnyOfTheseFactorsApply = "shipping-policy-module--ifAnyOfTheseFactorsApply--RVzEA";
export var infowebsitecom = "shipping-policy-module--infowebsitecom--EawUt";
export var logo = "shipping-policy-module--logo--bWOOn";
export var logo1 = "shipping-policy-module--logo1--tWObm";
export var navbarContent = "shipping-policy-module--navbarContent--fkpPg";
export var navbarContentMobile = "shipping-policy-module--navbarContentMobile--imZs-";
export var navigation = "shipping-policy-module--navigation--v9Yt8";
export var navigation1 = "shipping-policy-module--navigation1--ZkoiI";
export var p = "shipping-policy-module--p--jLvHk";
export var privacyPolicy = "shipping-policy-module--privacyPolicy--DdVzS";
export var proceedToYour = "shipping-policy-module--proceedToYour--ROjBE";
export var proceedToYourOrdersSelect = "shipping-policy-module--proceedToYourOrdersSelect--zecYS";
export var shippingPolicy = "shipping-policy-module--shippingPolicy--05GwE";
export var subtitle = "shipping-policy-module--subtitle--W2XFl";
export var subtitleLarge = "shipping-policy-module--subtitleLarge--6sRDb";
export var termsAndConditions = "shipping-policy-module--termsAndConditions--p8+A3";
export var textColumn = "shipping-policy-module--textColumn--mDMtW";
export var textbody = "shipping-policy-module--textbody--pLuLu";
export var textbody10 = "shipping-policy-module--textbody10--3CNws";
export var textbody11 = "shipping-policy-module--textbody11--G2W8P";
export var textbody2 = "shipping-policy-module--textbody2--E74n-";
export var textbody8 = "shipping-policy-module--textbody8--rVn9x";
export var textbody9 = "shipping-policy-module--textbody9--30Lty";
export var title = "shipping-policy-module--title--9H48J";
export var visaIcon = "shipping-policy-module--visaIcon--lQ8zJ";
export var weDoNotAcceptProductOrIt = "shipping-policy-module--weDoNotAcceptProductOrIt--L3yKJ";
export var youCanQuickly = "shipping-policy-module--youCanQuickly--0quf9";